<template>
  <div class="content-wrap">
    <!--    <el-button type="primary" @click="sendMsg">嗨嗨嗨</el-button>-->
    <div class="content-select" v-if="isRetail">
      <label>当前组织：</label>
      <organization-select :is-add-all="false" :model="selectForm"></organization-select>

      <el-popover placement="bottom" @show="showsss" title="默认显示：" width="200" v-model="visible">
        <div>
          <el-radio style="margin-top: 12px" v-model="radio" label="1">所在组织数据</el-radio><br />
          <el-radio style="margin-top: 12px" v-model="radio" label="2">全部组织数据</el-radio>
          <div style="text-align: right; margin-top: 12px">
            <span style="margin-right: 12px; cursor: pointer" @click="visible = false">取消</span>
            <span style="color: #508edc; cursor: pointer" @click="conserveSet">保存</span>
          </div>
        </div>
        <span slot="reference" v-show="activeName == 'already'" style="margin-left: 12px; color: #1890ff">设置</span>
      </el-popover>
      <label>当前数电账户：</label>
       <el-select
           v-if="currentAccount && currentAccount.elecAccount"
           v-model="currentAccount.elecAccount"
           @change="handleSelectAction"
      >
        <el-option
        v-for="account in accounts"
        :key="account.id"
        :label="account.payer + ' ' + account.elecAccount"
        :value="account.elecAccount"
        >
        
        <span>{{ account.payer + ' ' + account.elecAccount }}</span>

        <span v-if="account.loginState" class="online-indicator"></span>
        <span v-else class="offline-indicator"></span>
        </el-option>
      </el-select>
      <span style="margin-right: 10px; color: dimgrey;" v-else>当前组织尚未配置数电账户</span>
        <label v-if="activeName== 'manual'">当前项目：</label>
      <el-select v-model="currentProject"
        clearable
        filterable
        @change="handleProSelectAction"
        v-if="activeName== 'manual'">
          <el-option
            v-for="item in projects"
            :key="item.id"
            :label="item.projectName"
            :value="item.id">

          </el-option>
      </el-select>
    </div>
    <div class="ed_class" v-if="activeName == 'wait' && elecType == '1'">
      <div style="margin-top: 7px">
        <label>本月总额度：</label>
        <span>{{ totalAmountLimit }}</span>
      </div>
      <div style="margin-top: 7px; margin-left: 20px">
        <label>可用剩余额度：</label>
        <span>{{ availableRemainingLimit }}</span>
      </div>
      <div class="refresh">
        <el-button icon="el-icon-refresh" circle @click="handleInitInventory"></el-button>
      </div>
    </div>
    <div :class="isRetail ? 'content-tab' : ''">
      <el-tabs v-model="activeName" @tab-click="handleInvoiceOpenTabsChange">
        <el-tab-pane label="手工开票" name="manual" lazy>
          <retail-manual-invoice ref="manual" v-if="isRetail" @navigateToAlready="navigateToAlready"></retail-manual-invoice>
          <hotel-manual-invoice ref="manual" v-else @navigateToAlready="navigateToAlready"></hotel-manual-invoice>
        </el-tab-pane>
        <el-tab-pane label="开票记录" name="already" lazy>
          <retail-already-invoice @changeManual="handleChangeManual" ref="retailAlreadyInvoice" @setOrgIdEmit="setOrgIdEmit" v-if="isRetail && activeName == 'already'" :model="selectForm"></retail-already-invoice>
          <hotel-already-invoice @changeManual="handleChangeManual" v-if="!isRetail && activeName == 'already'"></hotel-already-invoice>
        </el-tab-pane>
      </el-tabs>
    </div>
    <taxBureauAuthDialog :visible.sync="authDialogVisible"></taxBureauAuthDialog>
  </div>
</template>

<script>
// 酒店行业
import HotelWaitInvoice from '../invoice-issue/hotel/HotelWaitInvoice';
import HotelManualInvoice from '../invoice-issue/hotel/HotelManualInvoice';
import HotelAlreadyInvoice from '../invoice-issue/hotel/HotelAlreadyInvoice';
// 零售行业
import RetailOriginalOrder from '../invoice-issue/retail/RetailOriginalOrder';
import RetailWaitInvoice from '../invoice-issue/retail/RetailWaitInvoice';
import RetailManualInvoice from '../invoice-issue/retail/RetailManualInvoice';
import RetailAlreadyInvoice from '../invoice-issue/retail/RetailAlreadyInvoice';

import OrganizationSelect from '@/components/widgets/OrganizationSelect';
import store from '@/store';
import { getCreditLimitByOrganization, getCreditByOrgId, getEquipmentByOrgId,getProjectByFilter } from '@/service/invoice-open';
import taxBureauAuthDialog from "@/components/taxBureauAuthDialog.vue";
import { getAccountList } from '@/service/rpa/rpa-auth';
export default {
  name: 'InvoiceIssue',
  components: {
    taxBureauAuthDialog,
    OrganizationSelect,
    HotelWaitInvoice,
    HotelManualInvoice,
    HotelAlreadyInvoice,
    RetailWaitInvoice,
    RetailOriginalOrder,
    RetailManualInvoice,
    RetailAlreadyInvoice
  },
  data() {
    return {
      radio: '1',
      visible: false,
      activeName: 'manual',
      elecType: '',
      selectForm: {
        orgId: localStorage.getItem('orgId') ? Number(localStorage.getItem('orgId')) : '',
        name: ''
      },
      // selectForm: { orgId: 0, account: null },
      salInvoiceIds: '[]',
      totalAmountLimit: '--',
      availableRemainingLimit: '--',
      authDialogVisible: false,
      accounts:[],
      projects:[],
      currentAccount:null,
      currentProject:null,
    };
  },
  watch: {
    'selectForm.orgId': {
      handler(value) {
        console.log(" 切换的组织id："+this.selectForm.orgId);
        store.commit('invoiceIssue/SET_CURRENT_ORGANIZATION', value);
        this.getAccount(this.selectForm.orgId)
        this.getProjects(this.selectForm.orgId)
        // console.log(this.activeName);
        if (this.activeName == 'wait') {
          this.getCreditByOrgId();
        }
      },
      immediate: true
    }
  },
  computed: {
    isRetail() {
      return this.$store.state.user.industry == 'RETAIL';
    }
  },
  created() {
    this.activeName = 'manual' // localStorage.getItem('activeName') || 'wait';
    this.radio = localStorage.getItem('setOrgId');
    const invoiceIds = this.$route.query.salInvoiceIds;
    if (invoiceIds) {
      this.activeName = "wait";
      this.invoiceIds = window.atob(invoiceIds);
    }
    console.log("当前选中的组织id"+this.selectForm.orgId)
    // this.getAccount(this.selectForm.orgId)
  },
  // mounted() {
  //   // 打开电子税务局认证弹窗
  //   this.authDialogVisible = true
  //   this.$bus.$on('elecType', (data) => {
  //     this.elecType = data;
  //     if (this.elecType == 1) {
  //       this.getCreditByOrgId();
  //     }
  //   });
  // },
  beforeDestroy() {
    this.$bus.$off('elecType');
  },
  methods: {
    showsss() {
      // debugger;
      if (localStorage.getItem('setOrgId') != 2) {
        this.radio = '1';
      } else {
        this.radio = localStorage.getItem('setOrgId');
      }
      console.log(1, this.radio);
    },
    conserveSet() {
      if (this.radio == 1) {
        this.selectForm.orgId = JSON.parse(localStorage.getItem('orgId' || ''));
        console.log(this.selectForm.orgId, 'this.selectForm.orgId');
      } else {
        this.selectForm.orgId = 0;
      }
      localStorage.setItem('setOrgId', this.radio);
      this.toast('默认组织设置成功', 'success');
      this.visible = false;
      this.$nextTick(() => {
        this.$refs.retailAlreadyInvoice.handleQuery();
      });
    },
    setOrgIdEmit(data) {
      if (data == 0) {
        this.selectForm.orgId = data;
        this.radio = '2';
      } else {
        this.radio = '1';
      }
    },
    async getEquipmentByOrgId() {
      let res = await getEquipmentByOrgId({ orgId: this.selectForm.orgId });
      if (res.success) {
        this.elecType = res.data.elecType;
        if (this.elecType == '1') {
          this.getCreditByOrgId();
        }
      }
    },
    async getCreditByOrgId() {
      // let res = await getCreditByOrgId(this.selectForm.orgId);
      // // console.log(res, 'rrrrrsseee');
      // if (res.success) {
      //   this.totalAmountLimit = res.data.totalAmountLimit ? res.data.totalAmountLimit : '--';
      //   this.availableRemainingLimit = res.data.availableRemainingLimit ? res.data.availableRemainingLimit : '--';
      // }
    },
    async getCreditLimitByOrganization() {
      // let res = await getCreditLimitByOrganization(this.selectForm.orgId);
      // // console.log(res, 'res试试');
      // if (res.success) {
      //   this.totalAmountLimit = res.data.totalAmountLimit ? res.data.totalAmountLimit : '--';
      //   this.availableRemainingLimit = res.data.availableRemainingLimit ? res.data.availableRemainingLimit : '--';
      // }
    },
    handleInitInventory() {
      this.getCreditLimitByOrganization();
    },
    // 发送消息
    sendMsg() {
      // this.$socket.send('嗨嗨嗨');
      //json数据
      // this.$socket.sendObj({ name: '嗨嗨嗨' });
    },
    // 发票开具Tabs切换
    handleInvoiceOpenTabsChange(tab) {
      this.activeName = tab.name;
      localStorage.setItem('activeName', tab.name);
    },
    // 开票记录-手工开票（复制发票）
    handleChangeManual() {
      this.activeName = 'manual';
      this.$nextTick(() => {
        this.$refs.manual.initCopyData();
      });
    },
    navigateToAlready() {
      this.activeName = 'already';
    },
    handleSelectAction(account) {
      console.log('选中的数电账户:', account);
      this.accounts.forEach(v => {
        if(v.elecAccount == account) {
          this.currentAccount = JSON.parse(JSON.stringify(v))
        }
      })
      localStorage.setItem('eleAccount', this.currentAccount.elecAccount);
      // 在这里处理数电账户选择后的逻辑
    },
    async getAccount(orgId) {
      this.currentAccount=null
      this.accounts=[]
      const { success, data } = await getAccountList(orgId);
      if (success ) {
        if(Array.isArray(data) && data.length>0){
          this.accounts = data;
          console.log(this.accounts);
          this.currentAccount = JSON.parse(JSON.stringify(data[0]));
          // this.selectForm.orgId = data[0].payer; // 初始化选中的账户
          localStorage.setItem('eleAccount', this.currentAccount.elecAccount);
        }
        else{
          this.toast('该组织没有维护数电账户，请先维护数电账户', 'error');
        }
      }
    },
    async getProjects(orgId){
      this.projects=[]
      const { success,data } =await getProjectByFilter({orgId: orgId});
      if(success){
        if(Array.isArray(data) && data.length >0){
          this.projects=data;
          console.log(JSON.stringify(this.projects));
        }
      }
    },
    handleProSelectAction(project) {
      console.log('选中的项目名称:', project);
      this.projects.forEach(v => {
        if(v.id == project) {
          this.currentProject = JSON.parse(JSON.stringify(v))
        }
      })
      console.log("当前的项目名称：" +this.currentProject)
      localStorage.setItem('projectId', project);
    },
  }
};
</script>

<style lang="scss" scoped>
.content-select {
  width: 900px;
  position: relative;
  top: 16px;
  left: calc(100% - 900px);
  z-index: 200;

  label {
    color: #333333;
    font-weight: 400;
    font-size: 16px;
  }

  ::v-deep .el-input__inner {
    border: none;
    border-radius: 0;
  }
}

.content-tab {
  margin-top: -24px;
  z-index: 1;
}

::v-deep .el-tabs__item {
  margin-top: 7px;
  color: #333333;
  font-weight: 400;
  font-size: 16px;
}

::v-deep .el-tabs__item.is-active {
  color: #3d94ff;
}
.content-wrap {
  position: relative;
}
.ed_class {
  z-index: 3333;
  position: absolute;
  z-index: 200;
  width: 500px;
  top: 16px;
  left: calc(100% - 780px);
  display: flex;
  color: #e89f68;
  font-size: 12px;
}
.refresh {
  color: #3d94ff;
  font-size: 20px;
  margin-left: 10px;
  .el-button {
    width: auto;
    height: auto;
    line-height: 1;
  }
  ::v-deep .el-button.is-circle {
    padding: 7px;
  }
}
.online-indicator {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: green;
    margin-left: 5px;
  }
  .offline-indicator {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: grey;
    margin-left: 5px;
  }
</style>
